import Card from "../UI/Card";
import { Docs, DotsAction, Plus } from "../UI/Icons";
import Dropdown from "../UI/Dropdown";
import DocumentsFormUpload from "./DocumentsFormUpload";
import ElectronicSigning from "../ElectronicSigning/ElectronicSigning";
import { ProfileStatus } from "../../models/profile";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";
import { useConfirm } from "../../hooks/use-confirm";
import ToggleSwitch from "../UI/ToggleSwitch";
import ButtonCard from "../UI/ButtonCard";
import { FileStatus } from "../../models/documents";
import Status from "../UI/Status";
import ContractStatusIndicator from "../ElectronicSigning/ContractStatusIndicator";
import styles from "./DocumentCard.module.css";
import { classnames } from "../../utils";
import SendMailForm from "./SendMailForm";
import DocumentDownloadLink from "../UI/DocumentDownloadLink";
import DocumentThumbnail from "../UI/DocumentThumbnail";
import { SignatureContratStatus } from "../../models/signatureElectronique";
import Oodrive from "../Oodrive/Oodrive";
import SendRecommandeForm from "../Laposte/SendRecommandeForm";
import SuiviRecommande from "../Laposte/SuiviRecommande";
import LaposteStatusIndicator from "../Laposte/LaposteStatusIndicator";

export default function DocumentCard({
  files,
  type,
  titre,
  tenant,
  isBailleur,
  deleteDocumentHandler,
  property,
  isLocataire,
  sendToElectronicSigning,
  updateFile,
  onDetailFiles,
  allowEdit,
  cancelElectronicSigning,
  relanceSignataire,
  sendToLaPoste,
}) {
  const [DocumentModal, closeDocumentModal, openDocumentModal] = useModal();
  const [SendMailModal, closeSendMailModal, openSendMailModal] = useModal();
  const [SendLetterModal, closeSendLetterModal, openSendLetterModal] =
    useModal();
  const [OpenSuiviLaposteModal, , openOpenSuiviLaposteModal] = useModal();
  const [OodriveModal, closeOodriveModal, openOodriveModal] = useModal();
  const [
    SignatureElectroniqueModal,
    closeSignatureElectroniqueModal,
    openSignatureElectroniqueModal,
  ] = useModal();
  const [fileToEdit, setFileToEdit] = useState();
  const [fileLaposteSuivi, setFileLaposteSuivi] = useState();
  const [typeDocument, setTypeDocument] = useState();
  const [Confirm, openConfirm] = useConfirm();

  const isFileSentForSigning =
    files.length === 1 && !!files[0].signature_electronique_status;
  const isFileSigned =
    isFileSentForSigning &&
    files[0].signature_electronique_status ===
      SignatureContratStatus.Archived.status;
  const isSignatureCanceled =
    isFileSentForSigning &&
    files[0].signature_electronique_status ===
      SignatureContratStatus.Canceled.status;
  const isFileSentWithLaposte =
    files.length === 1 && files[0].id_laposte !== null;

  async function sendRecommandeHandler(id_file, values) {
    await sendToLaPoste(id_file, values);
    closeSendLetterModal();
  }

  return (
    <>
      <Confirm
        onAnswer={(ok, fileId) => {
          if (ok) {
            deleteDocumentHandler(fileId);
          }
        }}
      >
        <p>
          Voulez-vous vraiment supprimer ce fichier ? Vous ne pourrez pas
          revenir en arrière.
        </p>
      </Confirm>
      <DocumentModal onClose={() => setFileToEdit(null)}>
        <DocumentsFormUpload
          property={property}
          tenant={tenant}
          closeDocumentModal={closeDocumentModal}
          isBailleur={isBailleur}
          isLocataire={isLocataire}
          file={fileToEdit}
          typeDocument={typeDocument}
        />
      </DocumentModal>
      <SendMailModal onClose={() => setFileToEdit(null)} size={"small"}>
        <SendMailForm
          tenant={tenant}
          file={fileToEdit}
          onSubmitted={closeSendMailModal}
        />
      </SendMailModal>
      <SendLetterModal onClose={() => setFileToEdit(null)} size={"small"}>
        <SendRecommandeForm
          tenant={tenant}
          file={fileToEdit}
          onSend={sendRecommandeHandler}
        />
      </SendLetterModal>
      <OpenSuiviLaposteModal
        onClose={() => setFileLaposteSuivi(null)}
        size={"intermediate"}
      >
        <SuiviRecommande file={fileLaposteSuivi} />
      </OpenSuiviLaposteModal>
      <OodriveModal>
        <Oodrive
          file={fileToEdit}
          onCancelSigning={async (fileId) => {
            try {
              await cancelElectronicSigning(fileId);
              closeOodriveModal();
            } catch (e) {
              console.error(e);
            }
          }}
          onRelance={async (fileId, signataireId) => {
            try {
              await relanceSignataire(fileId, signataireId);
            } catch (e) {
              console.error(e);
            }
          }}
        />
      </OodriveModal>
      <SignatureElectroniqueModal>
        <ElectronicSigning
          file={fileToEdit}
          propertyId={tenant?.id_bien}
          tenantProfiles={tenant?.profile_nom.profiles.filter(
            (profile) =>
              profile.garantor_of === null &&
              profile.status === ProfileStatus.Actif.status,
          )}
          onSendToElectronicSigning={async () => {
            await sendToElectronicSigning(fileToEdit.id);
            closeSignatureElectroniqueModal();
          }}
        />
      </SignatureElectroniqueModal>
      {files.length === 1 && (
        <Card className={"padding10"}>
          <div className={"flex space-between"}>
            <p className={classnames("cardTitle", styles.nowrap)}>
              {titre}{" "}
              {files[0].tag === "quittance" && files[0].meta["date_quittance"]}
            </p>
            <div className={"flex align-items-center"}>
              <Dropdown
                header={<DotsAction />}
                type={"click"}
                body={
                  <>
                    <div
                      onClick={() => {
                        setTypeDocument(type);
                        openDocumentModal();
                      }}
                    >
                      Ajouter un document
                    </div>
                    <div>
                      <DocumentDownloadLink
                        documentId={files[0].id}
                        file={files[0]}
                        isSigned={isFileSigned}
                      >
                        Visualiser
                      </DocumentDownloadLink>
                    </div>
                    <div>
                      <DocumentDownloadLink
                        documentId={files[0].id}
                        file={files[0]}
                        isSigned={isFileSigned}
                        save
                      >
                        Télécharger
                      </DocumentDownloadLink>
                    </div>
                    {updateFile && (
                      <div
                        onClick={() => {
                          setFileToEdit(files[0]);
                          openDocumentModal();
                        }}
                      >
                        Editer
                      </div>
                    )}
                    {sendToElectronicSigning &&
                      (!isFileSentForSigning || isSignatureCanceled) && (
                        <div
                          onClick={() => {
                            setFileToEdit(files[0]);
                            openSignatureElectroniqueModal();
                          }}
                        >
                          Signer électroniquement
                        </div>
                      )}
                    {isFileSentForSigning && !isSignatureCanceled && (
                      <div
                        onClick={() => {
                          setFileToEdit(files[0]);
                          openOodriveModal();
                        }}
                      >
                        Gérer signature électronique
                      </div>
                    )}
                    {tenant && isBailleur && (
                      <div
                        onClick={() => {
                          setFileToEdit(files[0]);
                          openSendMailModal();
                        }}
                      >
                        Envoyer par mail
                      </div>
                    )}
                    {tenant && isBailleur && !isFileSentWithLaposte && (
                      <div
                        onClick={() => {
                          setFileToEdit(files[0]);
                          openSendLetterModal();
                        }}
                      >
                        Envoyer par lettre recommandée
                      </div>
                    )}
                    {tenant && isBailleur && isFileSentWithLaposte && (
                      <div
                        onClick={() => {
                          setFileLaposteSuivi(files[0]);
                          openOpenSuiviLaposteModal();
                        }}
                      >
                        Suivre ma lettre recommandée
                      </div>
                    )}
                    {deleteDocumentHandler && (
                      <div onClick={() => openConfirm(files[0].id)}>
                        Supprimer
                      </div>
                    )}
                  </>
                }
              />
            </div>
          </div>
          {allowEdit &&
            (tenant ? (
              <Status
                color={"green"}
                text={"Partagé au locataire"}
                className={"text-right"}
              />
            ) : (
              <div className={"flex space-between align-items-center"}>
                <p className={"secondaryText marginT5"}>
                  Partagé au locataire{" "}
                </p>
                {files[0].status === FileStatus.aValider.status && (
                  <Status
                    color={"yellow"}
                    text={"A valider"}
                    className={"marginL5"}
                  />
                )}
                <p>
                  <ToggleSwitch
                    status={files[0].shared}
                    onToggle={() => {
                      updateFile(files[0].id, {
                        shared: !files[0].shared,
                      });
                    }}
                  />
                </p>
              </div>
            ))}
          <div className={"text-center marginT10"}>
            <DocumentDownloadLink
              documentId={files[0].id}
              file={files[0]}
              isSigned={isFileSigned}
            >
              <DocumentThumbnail
                documentId={files[0].id}
                file={files[0]}
                isSigned={isFileSigned}
              />
            </DocumentDownloadLink>
          </div>
          <LaposteStatusIndicator
            file={files[0]}
            onClick={() => {
              setFileLaposteSuivi(files[0]);
              openOpenSuiviLaposteModal();
            }}
          />
          <ContractStatusIndicator file={files[0]} />
        </Card>
      )}
      {files.length > 1 && (
        <ButtonCard
          onClick={() => onDetailFiles(files[0].tag)}
          className={"padding10 flex align-items-start"}
        >
          <div className="flex-grow">
            <p className={"cardTitle marginB20 text-left"}>{titre}</p>
            <p
              className={
                "text-center marginT10 marginAuto flex justify-content-center"
              }
            >
              <Docs text={`${files.length} documents`} />
            </p>
          </div>
        </ButtonCard>
      )}
      {files.length === 0 && (
        <ButtonCard
          className={"padding10 flex align-items-start"}
          onClick={() => {
            setTypeDocument(type);
            openDocumentModal();
          }}
        >
          <div className="flex-grow">
            <p className={"cardTitle marginB20 text-left"}>{titre}</p>
            <Plus size={"30px"} />
            <br />
            <p className="primaryText">Ajouter un document</p>
          </div>
        </ButtonCard>
      )}
    </>
  );
}
