import TextInput from "../../UI/TextInput";
import Button from "../../UI/Button";
import RadioGroup from "../../UI/RadioGroup";
import Radio from "../../UI/Radio";
import Tooltip from "../../UI/Tooltip";
import { Interrogation } from "../../UI/Icons";
import Checkbox from "../../UI/Checkbox";
import { ProfileStatus } from "../../../models/profile";
import Card from "../../UI/Card";
import Select from "../../UI/Select";
import MandataireFormFields from "../../Account/MandataireFormFields";
import useAccount from "../../../hooks/use-account";
import useLeaseTemplates from "../../../hooks/use-lease-templates";
import { DETAIL_CHARGES } from "../../../models/property";
import ExpandableCheckboxList from "../../UI/ExpandableCheckboxList";
import TextArea from "../../UI/TextArea";
import moment from "moment";
import useIrls from "../../../hooks/use-irls";
import { useFormikContext } from "formik";

const TYPE_ANNEE = "année";
const TYPE_TRIMESTRE = "trimestre";

export default function TenantConvertForm({
  propertyType,
  propertyIndice,
  tenant,
  previewBail,
  previewBailHandler,
  isValid,
  lease,
  updateLease,
}) {
  const { irls } = useIrls();
  const { account } = useAccount();
  const { leaseTemplates } = useLeaseTemplates();

  const { values, touched, setFieldValue, handleChange } = useFormikContext();

  const detailChargesCheckboxItems = [
    ...DETAIL_CHARGES,
    ...values.detail_charges.filter((i) => !DETAIL_CHARGES.includes(i)),
  ];

  function addItem(fieldName, item) {
    if (values[fieldName] === undefined || values[fieldName] === null) {
      setFieldValue(fieldName, [item]);
    } else if (!values[fieldName].includes(item)) {
      setFieldValue(fieldName, [...values[fieldName], item]);
    }
  }

  function updateIndice(e, type) {
    handleChange(e);
    const irl = irls.find(
      (irl) =>
        irl.annee ===
          parseInt(type === TYPE_ANNEE ? e.target.value : values.irl_annee) &&
        irl.trimestre ===
          parseInt(
            type === TYPE_TRIMESTRE ? e.target.value : values.irl_trimestre,
          ) &&
        irl.region === propertyIndice,
    );
    if (irl) {
      setFieldValue("irl_indice", irl.indice);
    } else {
      setFieldValue("irl_indice", "");
    }
  }
  return (
    <div className={"marginT10"}>
      {tenant?.profile_nom.profiles
        .filter((p) => p.status === ProfileStatus.Actif.status)
        .map((prof, index) => (
          <p key={index}>
            {prof.prenom} {prof.nom}
          </p>
        ))}
      <Select
        name="id_lease_template"
        label="Type de bail"
        mandatory
        className={"marginT10"}
        dependon="id_bien"
        onChange={(e) => {
          updateLease(parseInt(e.target.value));
          handleChange(e);
        }}
      >
        <option value="">Sélectionner un type de bail</option>
        {leaseTemplates?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.nom}
          </option>
        ))}
      </Select>
      <div className={"flex gap10 wrap"}>
        <TextInput
          type="number"
          name="loyer_hc"
          placeholder="389,45"
          dependon="id_bien"
          label={`Loyer ${lease.type !== "lcd" ? "mensuel" : ""}`}
          step={"0.01"}
          mandatory
          unit={"€"}
          className={"flex-grow"}
        />
        <TextInput
          type="number"
          name="charges"
          placeholder="45,38"
          dependon="id_bien"
          label={"Charges"}
          mandatory
          unit={"€"}
          step={"0.01"}
          className={"flex-grow"}
        />
      </div>
      {lease.type === "lcd" && (
        <>
          <TextInput
            type={"number"}
            name="taxe_sejour"
            placeholder={"2,3"}
            unit="€"
            label="Montant taxe de séjour total"
            step={"0.01"}
            mandatory
          />
          <TextInput
            type={"number"}
            name="nombre_personne"
            placeholder={"2"}
            unit="personnes"
            label="Nombre de personne dans le logement"
            mandatory
          />
          <TextInput
            type={"number"}
            name="montant_surcharge"
            placeholder={"2,3"}
            unit="€"
            label={
              <>
                Montant par personne supplémentaire{" "}
                <Tooltip
                  content={
                    "Pénalités que vous pourrez appliquer par personne, si il y a plus que le nombre de personne indiqué dans le contrat"
                  }
                >
                  <Interrogation />
                </Tooltip>
              </>
            }
            step={"0.01"}
            mandatory
          />
        </>
      )}
      {/*On l'affiche que si c'est un bail meublé ou civil*/}
      {(lease.type === "meuble" || lease.type === "civil") && (
        <RadioGroup>
          <Radio
            name="type_charge"
            value="provision"
            label="Provision sur charge"
            dependon="id_bien"
          />
          <Radio
            name="type_charge"
            value="forfait"
            label="Forfait de charge"
            dependon="id_bien"
          />
        </RadioGroup>
      )}
      Détail des charges facturées{" "}
      <Tooltip
        content={
          "Attention l’électricité n’est pas considérée comme une charge refacturable au locataire. Vous ne pouvez donc théoriquement pas l’inclure dans le forfait ou la provision de charge."
        }
      >
        <Interrogation />
      </Tooltip>{" "}
      :
      <ExpandableCheckboxList
        name="detail_charges"
        items={detailChargesCheckboxItems}
        addItem={addItem}
        classNameG={"marginT10 marginB20"}
        dependOn="id_bien"
      />
      {lease.type !== "mobilite" && (
        <>
          <TextInput
            type="number"
            name="deposit"
            placeholder="350"
            dependon="id_bien"
            label={"Montant du dépot de garantie"}
            mandatory
            unit={"€"}
            step={"0.01"}
          />
          {lease.type === "meuble" && values.deposit > values.loyer_hc * 2 && (
            <Card type={"error"} className={"padding5 marginB10"}>
              Attention, dans le cadre d'un bail meublé, il est interdit d'avoir
              un dépôt de garantie supérieur à 2 mois de loyer hors charge
            </Card>
          )}
          {lease.type === "nu" && values.deposit > values.loyer_hc && (
            <Card type={"error"} className={"padding5 marginB10"}>
              Attention, dans le cadre d'un bail nu, il est interdit d'avoir un
              dépôt de garantie supérieur à 1 mois de loyer hors charge
            </Card>
          )}
        </>
      )}
      {lease.type !== "lcd" && (
        <div className="flex wrap gap10 align-items-center">
          <TextInput
            type="number"
            name="day_payment"
            placeholder="05"
            dependon="id_bien"
            label={"Jour de paiement du loyer"}
            mandatory
            unit={"du mois"}
          />
          <Select name="payment_recurrence">
            <option value="1">Mensuel</option>
            <option value="3">Trimestriel</option>
            <option value="12">Annuel</option>
          </Select>
        </div>
      )}
      <Select name="raison_mobilite" hidden={lease.type !== "mobilite"}>
        <option value="">Choisir le motif du bail mobilité</option>
        <option value="en formation professionnelle">
          En formation professionnelle
        </option>
        <option value="en contrat d’apprentissage">
          En contrat d’apprentissage
        </option>
        <option value="en mission temporaire dans le cadre de son activité professionnelle">
          En mission temporaire dans le cadre de son activité professionnelle
        </option>
        <option value="en stage">En stage</option>
        <option value="en engagement volontaire dans le cadre de son service civique">
          En engagement volontaire dans le cadre de son service civique
        </option>
        <option value="en mutation professionnelle">
          En mutation professionnelle
        </option>
        <option value="en études supérieures">En études supérieures</option>)
      </Select>
      {lease.type === "lcd" && (
        <>
          <div className={"marginB10 flex"}>
            <Radio
              name="acompte"
              value="false"
              label={"Paiement intégral du séjour"}
              className={"flex-grow"}
            />
            <Radio
              name="acompte"
              value="true"
              label={"Versement d'un acompte"}
              className={"flex-grow"}
            />
          </div>
          {values.acompte === "false" ? (
            <TextInput
              type={"date"}
              label={"Date limite de paiement du séjour"}
              name={"date_paiement_solde"}
              mandatory
            />
          ) : (
            <>
              <TextInput
                type={"text"}
                label={"Pourcentage de l’acompte"}
                name={"pourcentage_acompte"}
                unit={"%"}
              />
              <Select
                name={"type_acompte"}
                label={"Paiement du solde de la réservation"}
              >
                <option value={"jour_entree"}>
                  Le jour de son entrée dans les lieux et de la remise des clés
                </option>
                <option value={"date_limite"}>
                  Avant son entrée dans les lieux, et au plus tard à la date
                  définie ci-après
                </option>
                <option value={"jour_depart"}>
                  Le jour de son départ des lieux et de la restitution des clés
                </option>
                <option value={"condition_specifique"}>
                  Dans les conditions particulières ci-après définies
                </option>
              </Select>
              {values.type_acompte === "date_limite" && (
                <TextInput
                  type={"date"}
                  label={"Date limite de versement du solde de la réservation"}
                  name={"date_limite_paiement_solde"}
                />
              )}
              {values.type_acompte === "condition_specifique" && (
                <TextArea
                  label={"Conditions du versement du solde de la réservation"}
                  rows="4"
                  name={"condition_paiement_solde"}
                />
              )}
            </>
          )}
        </>
      )}
      {lease.habitation && (
        <>
          <div className={"flex wrap gap10"}>
            <TextInput
              type="number"
              name="dernier_loyer"
              placeholder="370"
              label={
                <>
                  Dernier loyer versé{" "}
                  <Tooltip
                    direction={"top"}
                    content={
                      "Montant du dernier loyer hors charge versé par le locataire précédent. Cette information est obligatoire lorsque le dernier locataire a quitté le logement moins de dix-huit mois avant la signature du bail"
                    }
                  >
                    <Interrogation />
                  </Tooltip>
                </>
              }
              unit={"€"}
              step={"0.01"}
            />
            <TextInput
              type="date"
              name="date_dernier_loyer"
              label={"Date du dernier loyer perçu"}
            />
          </div>
          <div className={"flex wrap gap10"}>
            <TextInput
              type="number"
              name="montant_dernier_travaux"
              placeholder="370"
              label={"Montant des derniers travaux réalisés"}
              unit={"€"}
              step={"0.01"}
            />
            <TextInput
              type="text"
              name="nature_dernier_travaux"
              label={"Description des derniers travaux réalisés"}
              placeholder={"Remplacement chauffage"}
              className={"flex-grow"}
            />
          </div>
        </>
      )}
      {values.genBail === "false" && (
        <>
          <p>Indice de référence ({propertyIndice})</p>
          <div className={"flex gap10 wrap"}>
            <Select
              name={"irl_trimestre"}
              onChange={(e) => updateIndice(e, TYPE_TRIMESTRE)}
              className={"marginT0"}
            >
              <option value={"1"}>T1</option>
              <option value={"2"}>T2</option>
              <option value={"3"}>T3</option>
              <option value={"4"}>T4</option>
            </Select>
            <TextInput
              type="number"
              name="irl_annee"
              placeholder={"Année"}
              onChange={(e) => updateIndice(e, TYPE_ANNEE)}
            />
            <TextInput
              type="number"
              name="irl_indice"
              placeholder={"Indice"}
              step={"0.01"}
            />
          </div>
        </>
      )}
      {(values.genBail === "false" ||
        propertyType === "commercial" ||
        lease.type === "civil" ||
        lease.type === "stockage" ||
        lease.type === "garage") && (
        <>
          <TextInput
            type="number"
            name="duree_contrat"
            label={"Durée du contrat en mois"}
            placeholder={"36"}
            mandatory
            unit={"mois"}
          />
          {touched.duree_contrat &&
            ((lease.type === "commercial" && values.duree_contrat < 108) ||
              (lease.type === "commercial_derogatoire" &&
                values.duree_contrat > 36) ||
              (lease.type === "professionnel" &&
                values.duree_contrat < 72)) && (
              <Card type={"error"} className={"padding5 marginB10"}>
                Attention :
                <ul>
                  <li>Bail commercial : durée MINIMALE de 9 ans (108 mois)</li>
                  <li>
                    bail professionnel : durée MINIMALE de 6 ans (72 mois)
                  </li>
                  <li>bail dérogatoire : durée MAXIMALE de 3ans (36 mois)</li>
                </ul>
              </Card>
            )}
        </>
      )}
      {account.home === "/pro" && <MandataireFormFields />}
      {values.genBail === "true" ? (
        <>
          <TextInput
            type="date"
            name="debut"
            label={"Date de début du contrat"}
            mandatory
            className={"flex-grow"}
          />
          {lease.type === "lcd" && (
            <TextInput
              type="time"
              name="hour_start"
              label={"Heure de début du contrat"}
              mandatory
            />
          )}
          {(lease.type === "mobilite" || lease.type === "lcd") && (
            <>
              <TextInput
                type="date"
                name="fin"
                label={"Date de fin du contrat"}
                mandatory
              />
              {lease.type === "lcd" && (
                <TextInput
                  type="time"
                  name="hour_end"
                  label={"Heure de fin du contrat"}
                  mandatory
                />
              )}
              {lease.type === "mobilite" &&
                touched.fin &&
                (moment(values.fin).diff(moment(values.debut), "months") < 1 ||
                  moment(values.fin).diff(moment(values.debut), "months") >=
                    10) && (
                  <Card type={"error"} className={"padding5 marginB10"}>
                    Attention : un bail mobilité fait minimum 1 mois et maximum
                    10 mois. Il n'est ni renouvelable, ni reconductible. La date
                    maximale de fin du bail est entre le{" "}
                    {moment(values.debut).add(1, "months").format("DD/MM/YYYY")}{" "}
                    et le{" "}
                    {moment(values.debut)
                      .add(10, "months")
                      .format("DD/MM/YYYY")}
                  </Card>
                )}
            </>
          )}
          {(lease.type === "civil" || lease.type === "stockage") && (
            <>
              <div className={"flex wrap gap10 align-items-start"}>
                <TextInput
                  type="text"
                  name="preavis_locataire"
                  label={"Nombre de mois de préavis locataire"}
                  mandatory
                  className={"flex-grow"}
                  unit={"mois"}
                />
                <Select
                  name={"echeance_locataire"}
                  label={"Echéance du préavis"}
                >
                  <option value={"date_aniversaire"}>
                    A date anniversaire
                  </option>
                  <option value={"anytime"}>A tout moment</option>
                </Select>
              </div>
              <div className={"flex wrap gap10 align-items-start"}>
                <TextInput
                  type="text"
                  name="preavis_bailleur"
                  label={"Nombre de mois de préavis bailleur"}
                  mandatory
                  className={"flex-grow"}
                  unit={"mois"}
                />
                <Select
                  name={"echeance_bailleur"}
                  label={"Echéance du préavis"}
                >
                  <option value={"date_aniversaire"}>
                    A date anniversaire
                  </option>
                  <option value={"anytime"}>A tout moment</option>
                </Select>
              </div>
            </>
          )}
          {lease.type === "civil" && (
            <Checkbox name={"is_meuble"} className={"marginB10"}>
              Il s'agit d'un logement meublé
            </Checkbox>
          )}
          {(lease.type === "lcd" ||
            lease.type === "commercial" ||
            lease.type === "civil") && (
            <TextArea
              label={"Description du bien intégrée au bail"}
              rows="4"
              name={"description"}
              dependon="id_bien"
            />
          )}
          <TextArea
            label={"Article spécifique du bail"}
            rows="4"
            name={"article_specific"}
            dependon="id_bien"
          />
          {lease.habitation && (
            <Checkbox name={"join_notice"}>
              Joindre la notice (légalement obligatoire){" "}
            </Checkbox>
          )}

          <Button
            onClick={(e) => previewBailHandler(e, values)}
            disabled={!isValid}
            className={"marginT20 marginB20"}
          >
            Prévisualiser le bail
          </Button>
        </>
      ) : (
        <>
          <div className={"flex gap10 wrap"}>
            <TextInput
              type="date"
              name="debut"
              label={"Date de début du contrat"}
              mandatory
              className={"flex-grow"}
            />
            <TextInput
              type="date"
              name="date_signature"
              label={"Date de signature du bail"}
              mandatory
              className={"flex-grow"}
            />
          </div>
          {lease.type !== "lcd" && (
            <Checkbox name={"loyer_a_jour"}>
              Générer l'historique de paiement des loyers{" "}
              <Tooltip
                content={
                  "Qalimo génère par défaut tout l'historique d'appels de loyer. Cocher cette case permet également de générer l'intégralité des paiements du locataire, comme s'il était à jour dans ses paiements. Vous pouvez modifier ces informations par la suite."
                }
              >
                <Interrogation />
              </Tooltip>
            </Checkbox>
          )}
        </>
      )}
      <br />
      <div dangerouslySetInnerHTML={{ __html: previewBail }} />
    </div>
  );
}
